import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Itzafbq0WPY">
    <SEO title="Living Under the Influence - Living in Babylon" />
  </Layout>
)

export default SermonPost
